import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { capacidadeReservatorio } from "../../helpers/formulas";

import {
  ImagemReservatorio,
  Formulario,
  InputNumeroPessoas,
  InputReservatorio,
  TituloReservatorio,
  InputConsumoMedio,
  InputReservaDias,
  FormularioContainer,
  ResponseForm,
} from "./styled";

export default class Escada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resultado: 0,
      numMoradores: "",
      consumoMedio: 200,
      numDias: 2,
    };
  }

  handleInputPessoaChange = (e) => {
    this.setState({ numMoradores: Number(e.target.value) });
  };

  handleInputConsumoChange = (e) => {
    this.setState({ consumoMedio: Number(e.target.value) });
  };

  handleNumDias = (e) => {
    this.setState({ numDias: Number(e.target.value) });
  };

  handleSubmitReservatorio = (e) => {
    e.preventDefault();
    this.setState({
      resultado: capacidadeReservatorio(
        this.state.numMoradores,
        this.state.consumoMedio,
        this.state.numDias
      ),
    });
    this.setState({ inputReservatorioErro: false });
  };

  render() {
    const { numMoradores, consumoMedio, numDias, resultado } = this.state;
    return (
      <>
        <ImagemReservatorio>
          <Navbar
            bg="transparent"
            variant="transparent"
            className="border-bottom"
          >
            <Container>
              <Navbar.Brand href="/">
                <span>EuConstrutor</span>
              </Navbar.Brand>
              <Nav className=" d-flex justify-content-between">
                <Nav.Link href="/alvenaria">
                  <span>Alvenaria</span>
                </Nav.Link>
                <Nav.Link href="/hidraulica">
                  <span>Hidráulica</span>
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <h1>Calcule a capacidade do seu reservatório</h1>
        </ImagemReservatorio>
        <InputReservatorio>
          <TituloReservatorio>
            <p>
              Fórmula Utilizada:
              <br />
              NºPessoas * Consumo por pessoa (L) * Dias de reserva{" "}
            </p>
          </TituloReservatorio>
          <FormularioContainer>
            <ResponseForm>
              <h1>Seu reservatório deverá possuir a capacidade de:</h1>
              <p>{resultado} Litros</p>
            </ResponseForm>
            <Formulario>
              <InputNumeroPessoas>
                <label>Informe a quantidade de moradores na residência:</label>
                <input
                  type="text"
                  value={numMoradores}
                  onChange={this.handleInputPessoaChange}
                  placeholder="Numero de pessoas na residência"
                ></input>
              </InputNumeroPessoas>
              <InputConsumoMedio>
                <label>
                  Informe o consumo médio por pessoa em L/pessoa.
                  <br />
                  <span>*por padrão consideramos 200 L/Pessoa</span>
                </label>
                <input
                  type="text"
                  value={consumoMedio}
                  onChange={this.handleInputConsumoChange}
                  placeholder="Consumo médio diário por pessoa"
                ></input>
              </InputConsumoMedio>
              <InputReservaDias>
                <label>
                  Informe o número de dias de reserva
                  <br />
                  <span>* por padrão consideramos 2 dias de reserva</span>
                </label>
                <input
                  type="text"
                  value={numDias}
                  onChange={this.handleNumDias}
                  placeholder="Número de dias de reserva de água"
                ></input>
                <input
                  className="button"
                  onClick={this.handleSubmitReservatorio}
                  type="submit"
                  value="Calcular"
                  placeholder="botao"
                />
              </InputReservaDias>
            </Formulario>
          </FormularioContainer>
        </InputReservatorio>
      </>
    );
  }
}
