import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import {
  alturaEscada,
  numeroEspelhos,
  numeroPisos,
  comprimentoEscada,
} from "../../helpers/formulas";

import {
  ImagemEscada,
  Formulario,
  InputEspelho,
  FormAlturaDegrau,
  Resultado,
  InputNumeroEspelhos,
  TituloEspelho,
  TituloNumeroEspelhos,
  InputImagemPiso,
  ImagemEspelhos,
  InputsEspelho,
  Espelhos,
  RowFlexEspelhos,
  TodosOsResultados,
  ResultadoUm,
  ImagemResultadoUm,
  ResultadoDois,
  ImagemResultadoDois,
  ResultadoTres,
  ImagemResultadoTres,
  Piso,
  ImagePisoResult,
  GeneralContainer,
} from "./styled";

import ImagePiso from "../../images/piso.png";
import ImageAlturaEscada from "../../images/alturaescada.png";
import ImageComprimentoEscada from "../../images/comprimentoescada.png";
import ImageEspelho from "../../images/espelho.png";
import ImageTotalPisos from "../../images/totalpisos.png";
import ImageTotalEspelhos from "../../images/totalespelho.png";

export default class Escada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alturaDegrau: 18,
      resultado: "0",
      alturaPisoaPiso: "",
      alturaEspelho: 18,
      resultPiso: "0",
      resultEspelho: "0",
      resultComprimEscada: "0",
      inputErro: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ alturaDegrau: Number(e.target.value) });
  };

  handleInputPisoaPiso = (e) => {
    this.setState({ alturaPisoaPiso: Number(e.target.value) });
  };

  handleInputEspelho = (e) => {
    this.setState({ alturaEspelho: Number(e.target.value) });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ resultado: alturaEscada(this.state.alturaDegrau) });
    window.location.href = "#divPiso";
    this.setState({ inputErro: false });
  };

  handleEspelho = (e) => {
    e.preventDefault();
    const nEspelhos = numeroEspelhos(
      this.state.alturaPisoaPiso,
      this.state.alturaEspelho
    );

    this.setState({
      resultEspelho: nEspelhos,
      resultadoDoPiso: numeroPisos(nEspelhos),
    });

    const tamPiso = this.state.resultado;

    const totalPisos = numeroPisos(nEspelhos);

    this.setState({
      resultComprimEscada: comprimentoEscada(tamPiso, totalPisos),
    });

    if (tamPiso === "" || tamPiso === null || tamPiso === undefined) {
      window.location.href = "#divEspelho";
      this.setState({ inputErro: true });
    } else {
      window.location.href = "#todosOsResultados";
    }
  };

  render() {
    const {
      alturaDegrau,
      resultado,
      alturaPisoaPiso,
      alturaEspelho,
      resultEspelho,
      resultadoDoPiso,
      resultComprimEscada,
    } = this.state;
    return (
      <>
        <GeneralContainer>
          {" "}
          <ImagemEscada>
            <Navbar
              bg="transparent"
              variant="transparent"
              className="border-bottom"
            >
              <Container>
                <Navbar.Brand href="/">
                  <span>EuConstrutor</span>
                </Navbar.Brand>
                <Nav className=" d-flex justify-content-between">
                  <Nav.Link href="/alvenaria">
                    <span>Alvenaria</span>
                  </Nav.Link>
                  <Nav.Link href="/hidraulica">
                    <span>Hidráulica</span>
                  </Nav.Link>
                </Nav>
              </Container>
            </Navbar>
            <h1>Cálculo de escadas retas</h1>
          </ImagemEscada>
          <Formulario>
            <InputEspelho id="divEspelho">
              <TituloEspelho>
                <p>
                  Utilizando a fórmula de Blondel:
                  <br />
                  2E + P = 64 cm <br /> H / E{" "}
                </p>
              </TituloEspelho>
              <InputsEspelho>
                <InputImagemPiso>
                  <img src={ImageEspelho} alt="ImageEspelho" />
                </InputImagemPiso>
                <FormAlturaDegrau>
                  <label>
                    <p>Calcule a medida de cada piso do degrau em cm:</p>
                    Informe a altura do espelho em{" "}
                    <span> centímetros (cm) </span>.
                    <br />
                    *Por padrão utilizaremos 18 cm.
                  </label>
                  <input
                    type="text"
                    placeholder="Informe a altura de cada degrau em cm"
                    value={alturaDegrau}
                    onChange={this.handleInputChange}
                    style={{
                      border: this.state.inputErro ? "1px red solid" : null,
                    }}
                  ></input>

                  <input
                    className="button"
                    onClick={this.handleSubmit}
                    type="submit"
                    value="Calcular"
                    placeholder="botao"
                  />
                </FormAlturaDegrau>
              </InputsEspelho>
            </InputEspelho>
            <Piso id="divPiso">
              <Resultado>
                <p>
                  O valor de cada passo do degrau (ou piso) será de:
                  <span> {resultado} cm</span>
                </p>
              </Resultado>
              <ImagePisoResult>
                <img src={ImagePiso} alt="ImagePiso" />
              </ImagePisoResult>
            </Piso>
            <InputNumeroEspelhos>
              <TituloNumeroEspelhos>
                <h1>Calcule quantos degraus (espelhos) serão necessários:</h1>
              </TituloNumeroEspelhos>
              <Espelhos>
                <ImagemEspelhos>
                  <img src={ImageAlturaEscada} alt="ImageAlturaEscada" />
                </ImagemEspelhos>
                <RowFlexEspelhos>
                  <p>
                    Informe a altura total da escada, de piso a piso em
                    <span> centímetros (cm) </span>
                    conforme a imagem:
                  </p>
                  <input
                    type="text"
                    placeholder="informe a altura total da escada de piso a piso em cm"
                    value={alturaPisoaPiso}
                    onChange={this.handleInputPisoaPiso}
                  ></input>
                  <p>
                    Informe o valor de cada espelho em{" "}
                    <span> centímetros (cm)</span> : <br />
                    *O altura máxima indicada pela NBR 9050 é de 18 cm.
                  </p>
                  <input
                    type="text"
                    placeholder="Informe a altura de cada degrau em cm"
                    value={alturaEspelho}
                    onChange={this.handleInputEspelho}
                  ></input>
                  <input
                    type="submit"
                    className="button_second"
                    onClick={this.handleEspelho}
                    placeholder="enviar"
                    value="Calcular"
                  />
                </RowFlexEspelhos>
              </Espelhos>
            </InputNumeroEspelhos>
            <TodosOsResultados id="todosOsResultados">
              <ResultadoUm>
                <p>
                  A quantidade de espelhos da sua escada será de:
                  <span> {resultEspelho} espelhos</span>
                </p>
                <ImagemResultadoUm>
                  <img src={ImageTotalEspelhos} alt="ImageTotalEspelhos" />
                </ImagemResultadoUm>
              </ResultadoUm>
              <ResultadoDois>
                <p>
                  O numero de pisos da sua escada será de:
                  <span> {resultadoDoPiso} pisos</span>
                </p>
                <ImagemResultadoDois>
                  <img src={ImageTotalPisos} alt="ImageTotalPisos" />
                </ImagemResultadoDois>
              </ResultadoDois>
              <ResultadoTres>
                <p>
                  O comprimento da sua escada será de:
                  <span> {resultComprimEscada} cm</span>
                </p>
                <ImagemResultadoTres>
                  <img
                    src={ImageComprimentoEscada}
                    alt="ImageComprimentoEscada"
                  />
                </ImagemResultadoTres>
              </ResultadoTres>
            </TodosOsResultados>
          </Formulario>
        </GeneralContainer>
      </>
    );
  }
}
