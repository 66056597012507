import styled from "styled-components";

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const LeftPage = styled.div`
  width: 60%;
  height: 100vh;

  @media (max-width: 576px) {
    width: 100%;
    height: 80vh;
    margin-top: 3%;
  }
`;

export const RigthPage = styled.div`
  width: 50%;
  height: 100vh;

  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 576px) {
    width: 100%;
    height: 40vh;
    padding-top: 30%;

    img {
      display: none;
    }
  }
`;

export const ContentLeftPage = styled.div`
  padding-top: 5%;
  width: 90%;
  height: 40%;
  margin: 5%;
  span {
    color: #e36410;
  }
`;

export const HomePage = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterContent = styled.div`
  width: 100%;
  height: 30vh;
  background: #212120;

  p {
    color: #616160;
    text-align: center;
    margin-top: 5%;
  }

  @media (max-width: 576px) {
    width: 100%;
    height: 20vh;
    p {
      margin-top: 15%;
    }
  }
`;

export const CardsContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: -15%;
  padding-left: 5%;
  gap: 5%;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    margin-left: 8%;
    margin-top: -25%;
    justify-content: space-around;
  }
`;
