import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import {
  GeneralContainer,
  LeftPage,
  RigthPage,
  ContentLeftPage,
  HomePage,
  FooterContent,
  CardsContent,
} from "./styles";

import AvenariaImage from "../../images/wallpapers.jpg";
import HidroImage from "../../images/hidro.png";
import Logotipo from "../../images/alvenaria.png";
import "bootstrap/dist/css/bootstrap.min.css";

function Home() {
  return (
    <>
      <HomePage>
        <GeneralContainer>
          <LeftPage>
            <Navbar bg="transparent" variant="transparent">
              <Container>
                <Navbar.Brand href="/">EuConstrutor</Navbar.Brand>
                <Nav className="me-auto">
                  <Nav.Link href="/alvenaria">Alvenaria</Nav.Link>
                  <Nav.Link href="/hidraulica">Hidráulica</Nav.Link>
                </Nav>
              </Container>
            </Navbar>
            <ContentLeftPage>
              <h1>
                Uma plataforma para você realizar <span>cálculos</span>{" "}
                construtivos.
              </h1>
            </ContentLeftPage>

            <CardsContent>
              <Card
                style={{
                  height: "20rem",
                  width: "18rem",
                  background: "#063894",
                  color: "#ffff",
                }}
              >
                <Card.Img
                  variant="top"
                  style={{ width: "5rem" }}
                  src={HidroImage}
                />
                <Card.Body>
                  <Card.Title>Cálculo Hidráulico</Card.Title>
                  <Card.Text>
                    Encontre o tamanho ideal do seu reservatório! Nossa
                    calculadora realiza o cálculo das dimensões da caixa d'agua
                    da sua residência.
                  </Card.Text>
                  <Button variant="dark" href="/hidraulica">Calcular</Button>
                </Card.Body>
              </Card>
              <Card
                style={{
                  height: "20rem",
                  width: "18rem",
                  background: "#e36410",
                  color: "#ffff",
                }}
              >
                <Card.Img
                  variant="top"
                  style={{ width: "5rem" }}
                  src={Logotipo}
                />
                <Card.Body>
                  <Card.Title>Cálculo Alvenaria</Card.Title>
                  <Card.Text>
                    Qual o tamanho ideal da sua escada? ou, quantos tijolos
                    serão necessários para a sua construção? Nós calculamos pra
                    você!
                  </Card.Text>
                  <Button variant="dark" href="/alvenaria">Calcular</Button>
                </Card.Body>
              </Card>
            </CardsContent>
          </LeftPage>
          <RigthPage>
            <img src={AvenariaImage} alt="AvenariaImage" />
          </RigthPage>

          {/* <Navbar bg="dark">
          <Container>
            <Navbar.Brand href="#home">
              <img src={Logotipo} alt="Logotipo" width="50" height="50" />
            </Navbar.Brand>
          </Container>
        </Navbar> */}
          {/* 
        <Body>
          <AlvenariaDisplay>
            <Alvenaria>
              <Link to="/alvenaria">
                <img src={AvenariaImage} alt="AvenariaImage" />
              </Link>
            </Alvenaria>
            <AlvenariaText>
              <p>Alvenaria</p>
            </AlvenariaText>
          </AlvenariaDisplay>
          <Hidraulica>
            <HidroDisplay>
              <Link to="/hidraulica">
                <img src={HidroImage} alt="HidroImage" />
              </Link>
            </HidroDisplay>
            <TextHidro>
              <p>Hidráulica</p>
            </TextHidro>
          </Hidraulica>
        </Body> */}
        </GeneralContainer>
        <FooterContent>
          <p>©2023 | Eu Construtor</p>
        </FooterContent>
      </HomePage>
    </>
  );
}

export default Home;
