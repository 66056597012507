export const alturaEscada = (resultado) => {
  const resultNumber = Number(resultado);
  const finallyResult = 64 - 2 * resultNumber
  return `${finallyResult}`;
};

export const numeroEspelhos = (resulNumeroEspelhos, valorEspelhos) => {
  const numero_espelhos = Number(resulNumeroEspelhos / valorEspelhos);
  const numero_espelhos_end = numero_espelhos.toFixed();
  return `${numero_espelhos_end}`
};

export const numeroPisos = (resultEspelhos) => {
  return Number(resultEspelhos - 1);
};


export const comprimentoEscada = (tamPiso, totalPisos) => {
  const comprimentoEscadaTotal = Number(tamPiso * totalPisos)
  return `${comprimentoEscadaTotal}`
};

export const capacidadeReservatorio = (numMoradores, consumoMedio, numDias) => {
  return numMoradores * consumoMedio * numDias;
};

export const quantidadePisosArea = (areaLocal, areaPiso, percentual) => {
  const resultadoPeças = Number(areaLocal / areaPiso);
  if (isNaN(resultadoPeças)) {
    return "Verifique os valores fornecidos.";
  }
  const percentualFinal = Number(percentual / 100) * resultadoPeças;
  return `${Number(resultadoPeças + percentualFinal).toFixed()} Peças (Unidades)`;
};

export const quantidadeTijolos = (area, areaTijolo, percentual) => {
  if (percentual === 0 || null || undefined) {
    const resultArea = Number(area / areaTijolo);
    return `${resultArea.toFixed()} Unidades (sem percentual de quebra).`;
  } else {
    const resultArea = Number(area / areaTijolo);
    const percent = Number(resultArea * (1 + percentual / 100));

    return `${(
      resultArea + percent
    ).toFixed()} Unidades considerando ${percentual}% de quebra.`;
  }
};
