import styled from "styled-components";

export const GeneralContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const HeaderAlvenaria = styled.div`
  width: 100%;
  height:25%;
  background: #e05500;
  opacity: 0.8;

  span{
    color:#fff;
  }

  @media (max-width: 576px) {
    height: 15%;
  }
`;

export const BodyAlvenaria = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: center;
    gap: 20%;
  }
`;

export const Escada = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;

    &:hover {
      color: #e05500;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const Tijolos = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;

    &:hover {
      color: #e05500;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const Pisos = styled.div`
 margin-top: 5%;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;

    &:hover {
      color: #e05500;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }

`

export const ImagemEscada = styled.div`
  width: 15vw;
  height: 30vh;
margin-left:10%;
  img {
    width: 80%;
    height: 80%;
  }

  @media (max-width: 576px) {
    width: 40vw;
    height: 20vh;
  }
`;

export const ImagemPisos = styled.div`
  width: 15vw;
  height: 30vh;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    width: 40vw;
    height: 20vh;
  }
`;


export const ImagemTijolos = styled.div`
  width: 15vw;
  height: 30vh;
  margin-left:10%;
  img {
    width: 80%;
    height: 80%;
  }

  @media (max-width: 576px) {
    width: 40vw;
    height: 20vh;
  }
`;
