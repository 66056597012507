import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import PlantaArea from "../../images/planta.png";
import AreaPiso from "../../images/dimensoesPiso.png";

import {
  ImagemPisos,
  TituloPisos,
  ContainerPisos,
  InputsPisos,
  ImagemInputAreaTotal,
  InputAreaTotal,
  ContentInput,
  InputAreaPiso,
  ButtonDiv,
  InputPercentualQuebra,
  DivAreaPiso,
  ImagemInputAreaPiso,
  ContainerResultPisos,
} from "./styled";

import { quantidadePisosArea } from "../../helpers/formulas";

export default class Escada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areaTotal: "",
      percentualInput: 10,
      areaPiso: "",
    };
  }

  handleAreaTotal = (e) => {
    this.setState({ areaTotal: e.target.value.replace(",", ".") });
  };

  handleAreaPiso = (e) => {
    this.setState({ areaPiso: e.target.value.replace(",", ".") });
  };

  handlePercentual = (e) => {
    this.setState({ percentualInput: e.target.value.replace(",", ".") });
  };

  handleResultadoPisos = (e) => {
    e.preventDefault();
    const qtPisos = quantidadePisosArea(
      this.state.areaTotal,
      this.state.areaPiso,
      this.state.percentualInput
    );

    this.setState({
      resultadoQtPisos: qtPisos,
    });

    window.location.href = "#resultadoPisos";
  };

  render() {
    const { areaTotal, percentualInput, areaPiso, resultadoQtPisos } =
      this.state;
    return (
      <>
        <ImagemPisos>
          <Navbar
            bg="transparent"
            variant="transparent"
            className="border-bottom"
          >
            <Container>
              <Navbar.Brand href="/">
                <span>EuConstrutor</span>
              </Navbar.Brand>
              <Nav className=" d-flex justify-content-between">
                <Nav.Link href="/alvenaria">
                  <span>Alvenaria</span>
                </Nav.Link>
                <Nav.Link href="/hidraulica">
                  <span>Hidráulica</span>
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <h1>Calcule a quantidade de pisos necessários para a sua obra</h1>
        </ImagemPisos>
        <ContainerPisos>
          <TituloPisos>
            <p>
              Este cálculo utiliza a seguinte fórmula:
              <br />
              Área total(m²) / Área da peça (piso em m²)
            </p>
          </TituloPisos>
          <InputsPisos>
            <InputAreaTotal>
              <ContentInput>
                <label>
                  Some a área de todos os comodos onde será realizada a instalação
                  dos pisos <br />
                  <span>(O valor deve ser informado em m²):</span>
                </label>
                <input id="area"
                  type="text"
                  placeholder="Informe a área total do local"
                  value={areaTotal}
                  onChange={this.handleAreaTotal}
                ></input>
              </ContentInput>
              <ImagemInputAreaTotal>
                <img src={PlantaArea} alt="PlantaArea" />
              </ImagemInputAreaTotal>
            </InputAreaTotal>
            <DivAreaPiso>
              <InputAreaPiso>
                <label>
                  Informe a área de um piso que será utilizado na sua obra em
                  m²: <br />
                  <span>
                    *Exemplo: uma peça de 80 x 80 cm, será calculada: (0,8 m x
                    0,8 m = 0,64m² )
                  </span>
                </label>
                <input
                  type="text"
                  placeholder="Informe a área de uma unidade de piso"
                  value={areaPiso}
                  onChange={this.handleAreaPiso}
                ></input>
              </InputAreaPiso>
              <ImagemInputAreaPiso>
                <img src={AreaPiso} alt="AreaPiso" />
              </ImagemInputAreaPiso>
            </DivAreaPiso>

            <InputPercentualQuebra>
              <p>
                Informe o percentual de quebra:
                <br />
                <span>
                  *Por padrão definimos 10%, mas você pode modificar a qualquer
                  momento.
                </span>
              </p>
              <input
                type="text"
                value={percentualInput}
                onChange={this.handlePercentual}
              ></input>
            </InputPercentualQuebra>
            <ButtonDiv>
              <input
                type="submit"
                onClick={this.handleResultadoPisos}
                value="Calcular"
              />
            </ButtonDiv>
          </InputsPisos>
        </ContainerPisos>
        <ContainerResultPisos id="resultadoPisos">
          <h1>A quantidade de pisos necessários para a sua obra é de:</h1>
          <p>{resultadoQtPisos}</p>
        </ContainerResultPisos>
      </>
    );
  }
}
