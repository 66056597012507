import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import {
  GeneralContainer,
  HeaderHidro,
  BodyHidro,
  Reservatorios,
  ImagemHidro,
} from "./styled";

import Reservatorio from "../../images/reservatorio.png";

function Alvenaria() {
  return (
    <GeneralContainer>
      <HeaderHidro>
        <Navbar
          bg="transparent"
          variant="transparent"
          className="border-bottom"
        >
          <Container>
            <Navbar.Brand href="/">
              <span>EuConstrutor</span>
            </Navbar.Brand>
            <Nav className=" d-flex justify-content-between">
              <Nav.Link href="/alvenaria">
                <span>Alvenaria</span>
              </Nav.Link>
              <Nav.Link href="/hidraulica">
                <span>Hidráulica</span>
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
      </HeaderHidro>
      <BodyHidro>
        <Reservatorios>
          <Link to="/reservatorio">
            <ImagemHidro>
              <img src={Reservatorio} alt="Reservatorio" />
            </ImagemHidro>
            <p>
              Dimensionamento de <br />
              reservatório
            </p>
          </Link>
        </Reservatorios>
      </BodyHidro>
    </GeneralContainer>
  );
}

export default Alvenaria;
