import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Alvenaria from "./pages/Alvenaria";
import Hidraulica from "./pages/Hidraulica";
import Eletricidade from "./pages/Eletricidade";
import Escada from "./pages/Escada"
import Tijolos from "./pages/Tijolos"
import Reservatorio from "./pages/Reservatorio"
import Pisos from "./pages/Pisos"

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/alvenaria" element={<Alvenaria />} />
        <Route path="/pisos" element={<Pisos />} />
        <Route path="/hidraulica" element={<Hidraulica />} />
        <Route path="/eletricidade" element={<Eletricidade />} />
        <Route path="/escada" element={<Escada />} />
        <Route path="/tijolos" element={<Tijolos />} />
        <Route path="/reservatorio" element={<Reservatorio/>}/>
      </Routes>
    </BrowserRouter>
  );
}
