import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import {
  GeneralContainer,
  HeaderAlvenaria,
  BodyAlvenaria,
  Escada,
  Tijolos,
  ImagemEscada,
  ImagemTijolos,
  Pisos,
  ImagemPisos

} from "./styled";

import Escadas from "../../images/escadas.png";
import Tijolo from "../../images/tijolo.png";
import ImagePisos from "../../images/pisos.png"
function Alvenaria() {
  return (
    <GeneralContainer>
      <HeaderAlvenaria>
      <Navbar bg="transparent" variant="transparent" className="border-bottom">
              <Container>
                <Navbar.Brand href="/"><span>EuConstrutor</span></Navbar.Brand>
                <Nav className=" d-flex justify-content-between">
                  <Nav.Link href="/alvenaria"><span>Alvenaria</span></Nav.Link>
                  <Nav.Link href="/hidraulica"><span>Hidráulica</span></Nav.Link>
                </Nav>
              </Container>
            </Navbar>
      </HeaderAlvenaria>
      <BodyAlvenaria>
        <Escada>
          <Link to="/escada">
            <ImagemEscada>
              <img src={Escadas} alt="Escadas" />
            </ImagemEscada>

            <p>Cálculo de escada</p>
          </Link>
        </Escada>
        <Tijolos>
          <Link to="/tijolos">
            <ImagemTijolos>
              <img src={Tijolo} alt="Tijolo" />
            </ImagemTijolos>

            <p>Quantidade de tijolos</p>
          </Link>
        </Tijolos>

        <Pisos>
          <Link to="/pisos">
            <ImagemPisos>
              <img src={ImagePisos} alt="ImagePisos" />
            </ImagemPisos>

            <p>Cálculo de pisos</p>
          </Link>
        </Pisos>
      </BodyAlvenaria>
    </GeneralContainer>
  );
}

export default Alvenaria;
