import styled from "styled-components";

export const GeneralContainer = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const HeaderHidro = styled.div`
  width: 100%;
  height: 30%;
  background: #0d4c70;
  opacity: 0.8;

  span {
    color: #fff;
  }
`;

export const BodyHidro = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const Reservatorios = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;

  p {
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;

    &:hover {
      color: #0d4c70;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const ImagemHidro = styled.div`
  width: 15vw;
  height: 30vh;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    width: 50vw;
    height: 25vh;
  }
`;
