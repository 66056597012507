import styled from "styled-components";

export const ImagemPisos = styled.div`
  width: 100%;
  height: 25%;
  background: #8a9da4;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;

  h1 {
    font-family: Fjalla One;
    color: #fff;
    opacity: 0.7;
    font-size: 2em;
    margin-top: 2%;
    padding-left: 5%;
  }

  p {
    color: #fff;
    opacity: 0.7;
    padding-left: 5%;
    font-weight: bold;
  }

  span {
    color: #fff;
  }

  @media (max-width: 576px) {
    height: 15vh;
    text-align: start;
    h1 {
      font-size: 0.9em;
      margin-top: 5%;
    }
  }
`;

export const ContainerPisos = styled.div`
  width: 95vw;
  height: 100vh;
  position: relative;
  top: 25vh;
  left: 0;
  margin: 0;
`;

export const TituloPisos = styled.div`
  width: 50vw;
  margin-top: 2%;
  margin-left: 5%;
  font-weight: bold;

  @media (max-width: 576px) {
    width: 95vw;
    color: #222340;
    margin-left: 10%;
  }
`;

export const InputsPisos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputAreaTotal = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ContentInput = styled.div`
  width: 50%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  gap: 10%;
  margin-top: 10%;
  margin-left: 5%;
  align-items: start;

  input {
    height: 20%;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }

  span {
    font-weight: bold;
    font-size: 0.8em;
  }
  @media (max-width: 576px) {
    width: 85%;
    margin-left: 10%;
  }
`;

export const ImagemInputAreaTotal = styled.div`
  width: 25%;
  height: 50vh;
  margin-left: 10%;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    width: 80%;
    height: 35vh;
    margin-left: 10%;
  }
  @media (max-width: 300px) {
    width: 80%;
    margin-top: 40%;
  }
`;

export const InputAreaPiso = styled.div`
  width: 50%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  gap: 10%;
  margin-top: 10%;
  margin-left: 5%;
  align-items: start;
  input {
    height: 20%;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }

  span {
    font-weight: bold;
    font-size: 0.8em;
  }
  @media (max-width: 576px) {
    width: 85%;
    margin-left: 10%;
  }
  @media (max-width: 300px) {
    width: 85%;
    margin-left: 10%;
    margin-bottom: 40%;
    background: blue;
  }
`;
export const ButtonDiv = styled.div`
  height: 20vh;
  margin-left: 30%;
  input {
    width: 50%;
    height: 30%;
    margin-left: 5%;
    margin-bottom: 10%;
    align-items: center;
    background: blue;
    border-radius: 10px;
    border: 1px solid grey;
    background: #8a9da4;
    font-weight: bold;

    &:hover {
      background: #727e82;
    }
  }

  @media (max-width: 576px) {
    margin-top: 40%;
    margin-left: 5%;
    input {
      width: 85%;
    }
  }
`;

export const InputPercentualQuebra = styled.div`
  width: 95vw;
  height: 35vh;
  display: flex;
  flex-direction: column;
  margin-top: 5%;

  input {
    height: 18%;
    border-radius: 10px;
    border: 1px solid grey;
    width: 50%;
    margin: 5%;
    margin-top: 2%;
  }

  p {
    padding-left: 5%;
  }

  span {
    font-size: 0.8em;
    font-weight: bold;
  }

  @media (max-width: 576px) {
    margin-top: 10%;

    input {
      width: 85%;
      margin-left: 10%;
    }
    p {
      padding-left: 10%;
      font-weight: bold;
    }
  }
`;

export const DivAreaPiso = styled.div`
  margin-top: 5%;
  border-top: 1px solid #d2d4d2;
  display: flex;
  flex-direction: row;
  input {
    height: 20%;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const ImagemInputAreaPiso = styled.div`
  width: 25%;
  height: 50vh;
  margin-left: 10%;
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    width: 75%;
    height: 50vh;
    margin-left: 13%;
    margin-top: 5%;
  }
`;

export const ContainerResultPisos = styled.div`
  display: flex;
  flex-direction: column;
  width: 95vw;
  height: 50vh;
  position: relative;
  top: 100vh;

  h1 {
    margin-top: 5%;
    font-size: 1.7em;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 1.7em;
    color: #222340;
    font-weight: bold;
  }

  @media (max-width: 576px) {
    margin-top: 25%;
    height: 25vh;
  }

  @media (max-width: 300px) {
    margin-top: 500px;
    height: 25vh;
  }
`;
