import styled from "styled-components";

export const ImagemReservatorio = styled.div`
  width: 100%;
  height: 25vh;
  background: #0d4c70;
  margin: 0;
  position: absolute;
  top: 0;

  span {
    color: #fff;
  }

  h1 {
    font-family: Fjalla One;
    color: #fff;
    opacity: 0.7;
    font-size: 2em;
    padding-left: 5%;
    padding-top: 2%;
  }

  p {
    color: #fff;
    opacity: 0.7;
    padding-left: 5%;
    font-weight: bold;
  }

  @media (max-width: 576px) {
    height: 13vh;

    h1 {
      font-size: 1.2em;
      margin-top: 1%;
    }
  }
`;

export const TituloReservatorio = styled.div`
  font-size: 0.9em;
  text-align: start;
  padding-left: 5%;
  padding-top: 2%;
  font-weight: bold;

  @media (max-width: 576px) {
    margin-top: 10%;
    font-size: 0.8em;
  }
`;

export const InputReservatorio = styled.div`
  margin-top: 10%;
  position: absolute;
  top: 5%;
  width: 100%;
`;

export const InputNumeroPessoas = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8%;
  height: 20vh;
  width: 85%;
  align-items: start;
  padding-left: 10%;
  input {
    height: 30%;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const Formulario = styled.div`
  display: flex;
  width: 50vw;
  height: 80vh;
  flex-direction: column;
  padding-top: 3%;

  @media (max-width: 576px) {
    width: 90vw;
  }
`;

export const InputConsumoMedio = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8%;
  height: 20vh;
  width: 85%;
  align-items: start;
  padding-left: 10%;
  input {
    height: 30%;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }
  span {
    font-size: 13px;
    font-weight: bold;
  }

  @media (max-width: 576px) {
    width: 90vw;
  }
`;

export const InputReservaDias = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8%;
  height: 35%;
  width: 85%;
  align-items: start;
  padding-left: 10%;
  input {
    height: 30%;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }
  span {
    font-size: 13px;
    font-weight: bold;
  }

  .button {
    background: #0d4c70;
    margin-top: 4%;
    color: #ffff;
    cursor: pointer;
    &:hover {
      background: #08344d;
    }
  }

  @media (max-width: 576px) {
    width: 90vw;
    height: 22vh;
  }
`;

export const ResponseForm = styled.div`
  width: 50vw;
  height: 50vh;

  h1 {
    text-align: center;
    font-size: 25px;
    padding: 15%;
    color: #0d4c70;
  }

  p {
    text-align: center;
    font-weight: bold;
    font-size: 2em;
    color: #0d4c70;
    margin-top: -13%;
  }

  @media (max-width: 576px) {
    margin-top: -35%;
    width: 100vw;
  }
`;

export const FormularioContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;
