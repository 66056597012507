import styled from "styled-components";

export const ImagemTijolos = styled.div`
  width: 100%;
  height: 25vh;
  background: #bd5a09;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;

  h1 {
    font-family: Fjalla One;
    color: #fff;
    opacity: 0.7;
    font-size: 2em;
    margin-top: 2vh;
    padding-left: 5%;
  }

  p {
    color: #fff;
    opacity: 0.7;
    padding-left: 5%;
    font-weight: bold;
  }

  span {
    color: #fff;
  }

  @media (max-width: 576px) {
    height: 15vh;
    text-align: start;
    h1 {
      font-size: 0.9em;
      margin-top: 0.5rem;
    }
  }
`;

export const ContentTijolos = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 250vh;
  position: relative;
  top: 26vh;
  left: 0;
  margin: 0;
  padding: 5%;
  gap: 5%;

  p {
    font-weight: bold;
    font-size: 1.2em;
  }

  @media (max-width: 576px) {
    top: 20vh;
    left: 0;

    p {
      font-weight: bold;
      font-size: 0.9em;
      width: 80%;
    }
  }
`;
export const InfoTijolos = styled.div`
  width: 80vw;
`;

export const ImagemTijolosTamanhos = styled.div`
  width: 60vw;
  height: 45vh;
  margin-left: 15%;
  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    width: 80vw;
    height: 45vh;

    margin-top: -5rem;
    img {
      width: 80%;
      height: 100%;
      align-items: center;
    }
  }
`;
export const InputAreaTijolo = styled.div`
  width: 50vw;
  height: 30vh;

  input {
    height: 6vh;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }

  @media (max-width: 576px) {
    width: 90vw;
    height: 15vh;
  }
`;

export const ImagemAreaTotal = styled.div`
  width: 90vw;
  height: 45vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 5%;
  img {
    width: 50%;
    height: 100%;
  }
  @media (max-width: 576px) {
    width: 90vw;
    height: 45vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 5%;

    img {
      width: 90%;
      height: 70%;
    }
  }
`;

export const InputArea = styled.div`
  width: 50vw;
  height: 30vh;

  span {
    font-weight: bold;
    font-size: 0.8em;
  }

  input {
    height: 6vh;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }

  @media (max-width: 576px) {
    width: 90vw;
    height: 20vh;
  }
`;
export const InputPercentual = styled.div`
  width: 50vw;
  height: 30vh;
  span {
    font-weight: bold;
    font-size: 0.8em;
  }
  input {
    height: 6vh;
    border-radius: 10px;
    border: 1px solid grey;
    width: 100%;
  }
  @media (max-width: 576px) {
    width: 90vw;
    height: 10vh;
  }
`;

export const InputBotao = styled.div`
  height: 20vh;
  margin-left: 30%;
  button {
    width: 50%;
    height: 7vh;
    margin-left: 5%;
    margin-bottom: 10%;
    align-items: center;
    background: blue;
    border-radius: 10px;
    border: 1px solid grey;
    background: #d66b1a;
    border-color: #d66b1a;
    color: #fff;
    font-weight: bold;

    &:hover {
      background: #f5852a;
    }
  }
`;

export const RespostaQtdTijolos = styled.div`

  h1 {
    color: #bd5a09;
    font-size: 1.7em;
    text-align: center;
  }

  p {
    text-align: center;
    font-size: 1.7em;
    color: #222340;
    font-weight: bold;
  }

  @media (max-width: 576px) {
    height: 20vh;
    margin-bottom: 5rem;
    margin-top: -3rem;

    h1 {
      color: #bd5a09;
      font-size: 1.7em;
      text-align: center;
    }
    p {
      margin-left: 2rem;
      text-align: center;
      font-size: 1.3em;
      color: #222340;
      font-weight: bold;
    }
  }
`;
