import styled from "styled-components";

export const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImagemEscada = styled.div`
  width: 100%;
  height: 25vh;
  background: orange;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 0;
  padding: 1%;

  h1 {
    font-family: Fjalla One;
    color: #fff;
    opacity: 0.7;
    font-size: 2em;
    padding-left: 5%;
    padding-top: 2%;
  }

  @media (max-width: 576px) {
    height: 13vh;
    h1 {
      font-size: 1.3em;
    }
  }
`;

export const TituloEspelho = styled.div`
  font-size: 0.9em;
  text-align: start;
  padding-left: 15%;
  font-weight: bold;

  @media (max-width: 576px) {
    font-size: 0.7em;
    position: absolute;
    top: 15%;
  }
`;

export const InputsEspelho = styled.div`
  margin-top: 5%;
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;

  @media (max-width: 576px) {
    flex-direction: column;
    height: 50vh;
    margin-top: 55%;
    gap: 5%;
  }
`;

export const FormAlturaDegrau = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8%;

  p {
    color: #e36410;
    font-weight: bold;
    font-size: 1.1em;
  }

  span {
    font-weight: bold;
  }
  input {
    height: 15%;
    border-radius: 10px;
    border: 1px solid grey;
  }

  .button {
    background: orange;
    border: inherit;

    &:hover {
      background: #e36410;
    }
  }

  @media (max-width: 576px) {
    margin-top: 2%;

    input {
      height: 4vh;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 5%;
    }

    label {
      margin: 5% 10%;
    }
  }
`;

export const Formulario = styled.div``;

export const InputEspelho = styled.div`
  margin-top: 15%;
`;

export const InputImagemPiso = styled.div`
  width: 30%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    margin-left: 10%;
    height: 50%;
    width: 50%;
    margin-top: 5%;
  }
`;

export const InputNumeroEspelhos = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
  border-bottom: 1px solid #dcdcde;

  h1 {
    text-align: center;
    font-size: 1.5em;
    color: #e36410;
    font-weight: bold;
  }

  input {
    width: 70%;
    height: 6vh;
    border-radius: 10px;
    border: 1px solid grey;
    margin-bottom: 5%;
  }
  .button_second {
    background: orange;
    border: none;
    border: 1px solid black;
    margin-top: 5%;
    border: inherit;
    &:hover {
      background: #e36410;
    }
  }

  @media (max-width: 576px) {
    width: 90%;
    h1 {
      text-align: center;
      font-size: 1.5em;
    }
    input {
      width: 70%;
      height: 6vh;
      border-radius: 10px;
      border: 1px solid grey;
    }
  }
`;

export const Resultado = styled.div`
  width: 50%;
  height: 25vh;
  font-weight: bold;
  font-size: 1.3em;
  padding-left: 5%;
  padding-top: 5%;

  span {
    color: orange;
  }

  @media (max-width: 576px) {
    width: 100%;
    font-size: 1.2em;
    text-align: center;
  }
`;

export const Piso = styled.div`
  margin-top: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #dcdcde;
  border-bottom: 1px solid #dcdcde;

  @media (max-width: 576px) {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
  }
`;

export const ImagePisoResult = styled.div`
  width: 30%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    width: 50%;
    margin-left: 20%;
    margin-top: -15%;
  }
`;

export const TituloNumeroEspelhos = styled.div`
  text-align: center;
`;

export const Espelhos = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;

  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
`;
export const ImagemEspelhos = styled.div`
  width: 30%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    margin-left: 20%;
    height: 55%;
    width: 55%;
  }
`;

export const RowFlexEspelhos = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: bold;
  }

  @media (max-width: 576px) {
    font-size: 0.9em;
    text-align: start;

    p {
      padding-left: 10%;
    }

    input {
      width: 90%;
      margin-left: 10%;
      margin-bottom: 10%;
    }
  }
`;

export const TodosOsResultados = styled.div`
  margin-top: 10%;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20%;

  @media (max-width: 576px) {
    gap: 7%;
    margin-top: 15%;
  }
`;

export const ResultadoUm = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
  justify-content: space-around;
  font-weight: bold;
  font-size: 1.3em;
  border-top: 1px solid #dcdcde;
  align-items: center;

  span {
    color: orange;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
  }
`;

export const ImagemResultadoUm = styled.div`
  width: 30%;
  height: 100%;
  margin-top: 5%;

  @media (max-width: 576px) {
    width: 60%;
    height: 60%;
  }
`;

export const ResultadoDois = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
  justify-content: space-around;
  font-weight: bold;
  font-size: 1.3em;
  border-top: 1px solid #dcdcde;
  align-items: center;

  span {
    color: orange;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
  }
`;

export const ImagemResultadoDois = styled.div`
  width: 30%;
  height: 100%;
  margin-top: 5%;

  @media (max-width: 576px) {
    width: 60%;
    height: 60%;
  }
`;

export const ResultadoTres = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30%;
  justify-content: space-around;
  font-weight: bold;
  font-size: 1.3em;
  border-top: 1px solid #dcdcde;
  align-items: center;

  span {
    color: orange;
  }

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 576px) {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
  }
`;

export const ImagemResultadoTres = styled.div`
  width: 30%;
  height: 100%;
  margin-top: 5%;

  @media (max-width: 576px) {
    width: 60%;
    height: 60%;
  }
`;

