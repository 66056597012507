import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";

import { quantidadeTijolos } from "../../helpers/formulas";
import ImagemTijolosDimensoes from "../../images/tijolos.png";
import peDireitoImagem from "../../images/pedireito.png";
import informacoesImagem from "../../images/oitao.png";

import {
  ImagemTijolos,
  ContentTijolos,
  InfoTijolos,
  ImagemTijolosTamanhos,
  InputAreaTijolo,
  ImagemAreaTotal,
  InputArea,
  InputBotao,
  RespostaQtdTijolos,
  InputPercentual
} from "./styled";

export default class Escada extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Resultado: "",
      area: "",
      areaTijolo: "",
      percentual:"10"
    };
  }

  handleAreaTotal = (e) => {
    this.setState({ area: e.target.value.replace(",", ".") });
  };

  handlePercentual = (e) => {
    this.setState({ percentual: e.target.value.replace(",", ".") });
  };

  handleareaTijolo = (e) => {
    this.setState({ areaTijolo: e.target.value.replace(",", ".") });
  };
  handleResultadoTijolos = (e) => {
    e.preventDefault();
    this.setState({
      Resultado: quantidadeTijolos(this.state.area, this.state.areaTijolo, this.state.percentual),
    });

    window.location.href = "#resultadoPisos";
  };

  render() {
    const { area, areaTijolo, Resultado, percentual } = this.state;


    return (
      <>
        <ImagemTijolos>
          <Navbar
            bg="transparent"
            variant="transparent"
            className="border-bottom"
          >
            <Container>
              <Navbar.Brand href="/">
                <span>EuConstrutor</span>
              </Navbar.Brand>
              <Nav className=" d-flex justify-content-between">
                <Nav.Link href="/alvenaria">
                  <span>Alvenaria</span>
                </Nav.Link>
                <Nav.Link href="/hidraulica">
                  <span>Hidráulica</span>
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar>
          <h1>Calcule a quantidade de tijolos necessários para a sua obra</h1>
        </ImagemTijolos>
        <ContentTijolos>
          <InfoTijolos>
            <p>
              Este cálculo utiliza a seguinte fórmula:
              <br />
              (Área a ser construida / Área do tijolo) + percentual de quebra.
            </p>
          </InfoTijolos>
          <ImagemTijolosTamanhos>
            <img src={ImagemTijolosDimensoes} alt="ImagemTijolos" />
          </ImagemTijolosTamanhos>
          <InputAreaTijolo>
            <label>Informe a área do tijolo em m².</label>
            <input type="text" value={areaTijolo} onChange={this.handleareaTijolo}></input>
          </InputAreaTijolo>
          <ImagemAreaTotal>
            <img src={peDireitoImagem} alt="ImagemTijolos" />
            <img src={informacoesImagem} alt="ImagemTijolos" />
          </ImagemAreaTotal>
          <InputArea>
            <label>
              Informe a área total a ser construida em m²:
              <br />
             <span>Ex: Comprimento da parede X pé direito(Altura conforme imagem)</span> 
            </label>
            <input type="text"  value={area}  onChange={this.handleAreaTotal}></input>
          </InputArea>
          <InputPercentual>
            <label>
             Informe o percentual de quebra.
             <br/>
             <span>Para fins de cálculo consideramos 10%, mas você pode modificar a qualquer momento.</span> 
            </label>
            <input type="text"  value={percentual}  onChange={this.handlePercentual}></input>
          </InputPercentual>
          <InputBotao>
            <button type="submit"  onClick={this.handleResultadoTijolos}> Calcular</button>
          </InputBotao>
          <RespostaQtdTijolos id="resultadoTijolos">
            <h1>
              A quantidade de tijolos necessárias para a sua construção é de:
            </h1>
            <p>{Resultado}</p>
          </RespostaQtdTijolos>
        </ContentTijolos>
      </>
    );
  }
}
